var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", {
            domProps: { textContent: _vm._s(_vm.CampusDialogTitle) },
          }),
        ]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "菜单名称：", prop: "menuName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入菜单名称" },
                model: {
                  value: _vm.formData.menuName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "menuName", $$v)
                  },
                  expression: "formData.menuName",
                },
              }),
            ],
            1
          ),
          _vm.formData.type === "1" || _vm.formData.type === "2"
            ? _c(
                "el-form-item",
                { attrs: { label: "菜单路由：", prop: "url" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入菜单名称" },
                    model: {
                      value: _vm.formData.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "url", $$v)
                      },
                      expression: "formData.url",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.formData.type === "3"
            ? _c(
                "el-form-item",
                { attrs: { label: "按钮权限：", prop: "perms" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入按钮权限" },
                    model: {
                      value: _vm.formData.perms,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "perms", $$v)
                      },
                      expression: "formData.perms",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "菜单类型", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择菜单类型" },
                  model: {
                    value: _vm.formData.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "type", $$v)
                    },
                    expression: "formData.type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "目录", value: "1" } }),
                  _c("el-option", { attrs: { label: "菜单", value: "2" } }),
                  _c("el-option", { attrs: { label: "按钮", value: "3" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "菜单图片：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入菜单图片" },
                model: {
                  value: _vm.formData.icon,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "icon", $$v)
                  },
                  expression: "formData.icon",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "菜单顺序：", prop: "orderNum" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入菜单顺序" },
                model: {
                  value: _vm.formData.orderNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "orderNum", $$v)
                  },
                  expression: "formData.orderNum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "菜单状态：" } },
            [
              _c("el-switch", {
                model: {
                  value: _vm.formData.state,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "state", $$v)
                  },
                  expression: "formData.state",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "primary" },
              on: { click: _vm.saveRoleForm },
            },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              staticClass: "dialog-btn",
              attrs: { type: "info" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }