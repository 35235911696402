<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="CampusDialogTitle"></span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="100px">
      <el-form-item label="菜单名称：" prop="menuName">
        <el-input v-model="formData.menuName" placeholder="请输入菜单名称"></el-input>
      </el-form-item>
      <el-form-item v-if="formData.type === '1' || formData.type === '2'" label="菜单路由：" prop="url">
        <el-input v-model="formData.url" placeholder="请输入菜单名称"></el-input>
      </el-form-item>
      <el-form-item v-if="formData.type === '3'" label="按钮权限：" prop="perms">
        <el-input v-model="formData.perms" placeholder="请输入按钮权限"></el-input>
      </el-form-item>
      <el-form-item label="菜单类型" prop="type">
        <el-select v-model="formData.type" placeholder="请选择菜单类型">
          <el-option label="目录" value="1"></el-option>
          <el-option label="菜单" value="2"></el-option>
          <el-option label="按钮" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="菜单图片：">
        <el-input v-model="formData.icon" placeholder="请输入菜单图片"></el-input>
      </el-form-item>
      <el-form-item label="菜单顺序：" prop="orderNum">
        <el-input v-model="formData.orderNum" placeholder="请输入菜单顺序"></el-input>
      </el-form-item>
      <el-form-item label="菜单状态：">
        <el-switch v-model="formData.state"></el-switch>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-button type="primary" @click="saveRoleForm" class="dialog-btn">确 定</el-button>
      <el-button @click="close" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
  import { insertMenu,updateMenu} from '@/api/system/menu'
  export default {
    props: {
      show: {
        default: false,
        type: Boolean
      },
      pid:{
        default: "",
        type: String
      },
      buttonType:{
        default: false,
        type: Boolean
      },
      MenuData: {
        default: () => {
          return {}
        },
        type: Object
      }
    },
    watch: {
      show () {
        if (this.show) {
          if (this.$refs.formData) {
            this.$refs.formData.resetFields()
          }
        }
      }
    },
    data () {
      return {
        CampusDialogTitle: '',
        formData: {
          state:true
        },
        rules: {
          menuName: [{ required: true, message: '请输入菜单名称', trigger: 'blur' }],
          url: [{ required: true, message: '请输入菜单路由', trigger: 'blur' }],
          perms: [{ required: true, message: '请输入按钮权限', trigger: 'blur' }],
          type: [{ required: true, message: '请输入类型', trigger: 'blur' }],
          orderNum: [{ required: true, message: '请输入顺序', trigger: 'blur' }]
        }
      }
    },
    methods: {
      handlechange() {
        this.$forceUpdate()
      },
      openDialog () {
        console.log(JSON.stringify(this.MenuData))
        this.formData = {}
        if (this.buttonType) {
          this.CampusDialogTitle = '新增菜单'
        } else {
          this.CampusDialogTitle = '编辑菜单'
          this.MenuData.state  = this.MenuData.state === "1"?true:false
          this.formData = Object.assign({}, this.MenuData)
          this.formData.type = this.formData.type + ""
        }
      },

      // 关闭
      close () {
        this.$emit('close')
      },
      async insertMenu(formData) {
        console.log(JSON.stringify(this.formData))
        const response = await insertMenu({
          pid:this.pid,
          name:this.formData.menuName,
          url:this.formData.url,
          perms:this.formData.perms,
          type:parseInt(this.formData.type),
          icon:this.formData.icon,
          orderNum:this.formData.orderNum,
          state:this.formData.state?"1":"2"
        })
        if(response.state === 'success') {
          this.close()
          this.$emit('updataMenu')
        }
      },
      async updateMenu(formData) {
        const response = await updateMenu({
          id:this.formData.id,
          pid:this.formData.pId,
          name:this.formData.menuName,
          url:this.formData.url,
          perms:this.formData.perms,
          type:this.formData.type,
          icon:this.formData.icon,
          orderNum:this.formData.orderNum,
          state:this.formData.state?"1":"2"
        })
        if(response.state === 'success') {
          this.close()
          this.$emit('updataMenu')
        }
      },
      saveRoleForm () {
        this.$refs.formData.validate(valid => {
          if (valid) {
            if (this.buttonType) {
              this.insertMenu(this.formData)
            } else {
              this.updateMenu(this.formData)
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../style/dialog.scss';
</style>
