var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "block" },
    [
      _c("el-tree", {
        staticStyle: { padding: "20px" },
        attrs: {
          data: _vm.dataList,
          props: _vm.defaultProps,
          "node-key": "id",
          "expand-on-click-node": false,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ node, data }) {
              return _c(
                "span",
                { staticClass: "custom-tree-node" },
                [
                  _c("span", { staticStyle: { "font-size": "14px" } }, [
                    _vm._v(_vm._s(node.label)),
                  ]),
                  _c("el-button", {
                    staticStyle: { "font-size": "18px", "margin-left": "20px" },
                    attrs: {
                      icon: "el-icon-circle-plus-outline",
                      type: "text",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.append(data)
                      },
                    },
                  }),
                  node.label !== "全部菜单"
                    ? _c("el-button", {
                        staticStyle: {
                          "font-size": "18px",
                          "margin-left": "20px",
                        },
                        attrs: { icon: "el-icon-edit", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.edit(data)
                          },
                        },
                      })
                    : _vm._e(),
                  node.label !== "全部菜单"
                    ? _c("el-button", {
                        staticStyle: {
                          "font-size": "18px",
                          "margin-left": "20px",
                        },
                        attrs: { icon: "el-icon-remove-outline", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.confirmDelete(data)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            },
          },
        ]),
      }),
      _c("append-menu", {
        attrs: {
          show: _vm.appendMenuDialog,
          pid: _vm.pid,
          buttonType: _vm.buttonType,
          MenuData: _vm.MenuData,
        },
        on: {
          updataMenu: _vm.getMenuTreeList,
          close: function ($event) {
            _vm.appendMenuDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }