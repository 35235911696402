<template>
  <div class="block">
    <el-tree
      style="padding: 20px"
      :data="dataList"
      :props="defaultProps"
      node-key="id"
      :expand-on-click-node="false">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span style="font-size: 14px;">{{ node.label }}</span>
          <el-button
            style="font-size: 18px;margin-left: 20px;"
            icon="el-icon-circle-plus-outline"
            type="text"
            @click="append(data)">
          </el-button>
          <el-button
            v-if="node.label !== '全部菜单'"
            style="font-size: 18px;margin-left: 20px;"
            icon="el-icon-edit"
            type="text"
            @click="edit(data)">
          </el-button>
          <el-button
            v-if="node.label !== '全部菜单'"
            style="font-size: 18px;margin-left: 20px;"
            icon="el-icon-remove-outline"
            type="text"
            @click="confirmDelete(data)">
          </el-button>
      </span>
    </el-tree>
    <append-menu :show="appendMenuDialog" :pid="pid"  @updataMenu="getMenuTreeList" @close="appendMenuDialog = false" :buttonType = "buttonType" :MenuData="MenuData"></append-menu>
  </div>
</template>

<script>
  import { getMenuTreeList,deleteMenu} from '@/api/system/menu'
  import appendMenu from '@/components/system/addMenu'
    export default {
      name: "menu",
      data () {
        return {
          dataList: [],
          MenuData:{},
          buttonType:true,
          pid:"",
          appendMenuDialog:false,
          defaultProps: {
            children: 'children',
            label: 'menuName'
          },
          pagination: {
            currentPage: 1,
            pageSize: 10,
            pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            layout: 'total, sizes, prev, pager, next, jumper',
            total: 0
          },
        }
      },
      components:{
        appendMenu,
      },
      created () {
        this.getMenuTreeList()
      },
      methods: {
        async deleteMenu(thembMenuId) {
          const res = await deleteMenu({
            menuId:thembMenuId
          })
         if(res.state === "success") {
           this.getMenuTreeList()
         }
        },
        // 获取学员列表
        async getMenuTreeList() {
          this.dataList = []
          const res = await getMenuTreeList({})
          var thembObject = {}
          thembObject.menuName = "全部菜单"
          thembObject.pId = "0"
          thembObject.id = "0"
          thembObject.type = 0
          thembObject.children = res.body
          this.dataList.push(thembObject)
        },
        append(data) {
          this.appendMenuDialog = true
          this.buttonType = true
          this.MenuData = data
          this.pid = data.id
        },
        edit(data) {
          this.appendMenuDialog = true
          this.buttonType = false
          this.MenuData = data
          this.pid = data.id
        },
        remove(data) {
          this.deleteMenu(data.id)
        },
        confirmDelete(data) {
          this.$confirm('删除此菜单，子菜单也会随之删除，是否确认删除？', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              this.remove(data)
            })
            .catch(() => {
              return false
            })
        }
      }
    }

</script>

<style scoped>
.block {
  padding: 20px;
}
</style>
